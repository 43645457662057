export default {
    methods: {
      contact(param) {
        if (param == "open") {
          document.getElementById("modal").style.display = "block";
          document.getElementById("modal-elements").style.display = "block";
        } else if (param == "close") {
          document.getElementById("modal").style.display = "none";
          document.getElementById("modal-elements").style.display = "none";
        }
      },
    },
  };