<template>
  <div id="header">
    <a style="z-index: 2" href="/"
      ><img
        id="logo"
        alt="Antriksh India Group"
        src="https://centralavenue.s3.ap-south-1.amazonaws.com/public/logo.jpeg"
    /></a>
    <div id="phone">
      <a href="tel:+919718880334" id="number">+91 971-888-0334</a>
      <a href="tel:+919718880334" id="call"
        ><img class="icon" alt="call now" src="../assets/icons/phone.svg" />
        Call Us Now</a
      >
    </div>
  </div>
  <div id="background">
    <img
      id="background-image"
      alt="Central Avenue"
      src="https://centralavenue.s3.ap-south-1.amazonaws.com/public/background.webp"
    />
    <div id="cover"></div>
  </div>
  <div id="text">
    <h1>CENTRAL AVENUE</h1>
    <p>ESTATE RESIDENCES</p>
    <h2>Aspirational Living Spaces</h2>
    <p>SECTOR-33, GURUGRAM</p>
    <button @click="contact('open')" class="cta" id="contact">
      <span>Get In Touch &rarr;</span>
    </button>
  </div>
</template>

<script>
import mixin from "../mixins/modal.js";
export default {
  name: "Background",
  mixins: [mixin],
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=MonteCarlo&family=Nanum+Myeongjo:wght@800&display=swap");
@import url("../assets/css/button.css");
#header {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding-top: 1vh;
}
#logo {
  width: 300px;
  margin-left: 2vw;
  z-index: 2;
  border-radius: 10px 5px 5px 7px;
  height: 50px;
}
#phone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 2vw;
  z-index: 2;
}
#number {
  color: white;
  font-weight: bold;
  font-size: 22px;
}
#call {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.icon {
  height: 20px;
  margin-right: 5px;
}
@media screen and (max-width: 700px) {
  #logo {
    width: min(300px, 50vw);
    margin-left: 1vw;
    height: 40px;
  }
  #number {
    font-size: min(22px, 4.2vw);
  }
  #call {
    font-size: min(14px, 4vw);
  }
  .icon {
    height: 15px;
  }
}
#background-image {
  width: 100vw;
  height: max(470px, 70vh);
  position: absolute;
  top: 0;
}
#cover {
  width: 100vw;
  height: max(470px, 70vh);
  position: absolute;
  top: 0;
  background-color: black;
  opacity: 0.5;
}
#text {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-left: 8vw;
  width: fit-content;
  margin-top: 15vh;
  color: white;
  align-items: center;
}
#text h1 {
  font-family: "Nanum Myeongjo", serif;
  font-weight: bolder;
  font-size: 40px;
  margin: 5px 0;
}
#text h2 {
  font-family: "MonteCarlo", cursive;
  font-size: 60px;
  margin: 0;
}
#contact {
  margin-top: 5vh;
  width: min(200px, 62vw);
  font-family: "Nanum Myeongjo", serif;
  font-size: 18px;
}
@media screen and (max-width: 700px) {
  #text {
    margin-left: 5vw;
  }
  #text h1 {
    font-size: min(40px, 8vw);
  }
  #text h2 {
    font-size: min(60px, 10vw);
  }
  #text p {
    font-size: min(16px, 5vw);
  }
  #contact span {
    font-size: 16px;
  }
}
</style>