<template>
  <Modal/>
  <div id="routine">
  <Background/>
  <Header />
  <button @click="contact('open')" id="trigger" class="cta" style="display: inline-block;"><span>Enquire Now</span></button>
  </div>
</template>

<script>
import Header from './Header.vue';
import Background from './Background.vue';
import Modal from './Modal.vue';
import mixin from "../mixins/modal.js";
export default {
  name: 'Index',
  mixins: [mixin],
  components: {
    Background,Modal,Header
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@800&display=swap");
#routine {
  position: absolute;
  top: 0;
}
#trigger{
  position: fixed;
  bottom: 0;
  margin-left: 2vw;
  z-index: 2;
  background-color: black;
  color: white;
}
#trigger span{
  font-size: 22px!important;
  font-family: 'Times New Roman', Times, serif;
}
</style>
